import React, { useEffect, useState } from 'react';
import styles from './Table.module.css';
import { renderCell } from './utils/renderCell';
import { formatNumber, formatDate } from '../../helpers/common';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const PivotTableV2 = ({
  dataSource,
  dataConfig,
  grandTotal,
  prevGrandTotal,
  movementTotal,
  searchColumn = true,
  onSearch,
  onSortAsc,
  onSortDesc,
  onClearFilter,
  actionOnClick,
  sticky = false,
}) => {
  const [sortedDataSource, setSortedDataSource] = useState(dataSource);
  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setSortedDataSource(dataSource);

    if (!onSearch) {
      onClickHeaderSortingAsc(dataConfig[0].dataIndex);
    }

    // Dont reset filters when calling onSearch
    if (onSearch) {
      return;
    }
    setFilters({});
  }, [dataSource]);

  useEffect(() => {
    handleClearFilters();
  }, [onClearFilter]);

  const showGrandTotal = (index) => {
    if (index === 0) {
      return 'Grand Total';
    }

    if (index === dataConfig.length - 4) {
      return formatNumber(prevGrandTotal);
    }
    if (index === dataConfig.length - 3) {
      return formatNumber(grandTotal);
    }
    if (index === dataConfig.length - 2) {
      return formatNumber(movementTotal);
    }
  };

  const onClickHeaderSortingAsc = (dataIndex) => {
    if (onSortAsc) {
      return onSortAsc(dataIndex);
    }
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return -1; // null is smaller
      if (valueB === null) return 1; // null is smaller

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      return Number(valueA) - Number(valueB);
    });
    setSortedDataSource(sortedData);
  };

  const onClickHeaderSortingDesc = (dataIndex) => {
    if (onSortDesc) {
      return onSortDesc(dataIndex);
    }
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return 1; // null is larger
      if (valueB === null) return -1; // null is larger

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      }

      return Number(valueB) - Number(valueA);
    });
    setSortedDataSource(sortedData);
  };

  const handleFilterChange = (dataIndex, value) => {
    const newFilters = {
      ...filters,
      [dataIndex]: onSearch ? value : value.toLowerCase(),
    };

    setFilters(newFilters);

    const allFiltersEmpty = Object.values(newFilters).every(
      (filterValue) => filterValue === ''
    );

    if (onSearch) {
      return onSearch(newFilters);
    }

    if (allFiltersEmpty) {
      setSortedDataSource(dataSource);
      onClickHeaderSortingAsc(dataConfig[0].dataIndex);
      return;
    }

    const filtered = dataSource.filter((record) => {
      return Object.keys(newFilters).every((key) => {
        const cellValue = record[key];
        const filterValue = newFilters[key];

        if (!filterValue) return true;

        if (cellValue === null || cellValue === undefined) {
          return false;
        }

        // Special handling for date fields
        if (key === 'recordDate' || key === 'date') {
          const formattedDate = formatDate(cellValue).toLowerCase();
          return formattedDate.includes(filterValue);
        }

        if (
          key === 'prevBalanceUSD' ||
          key === 'currentBalanceUSD' ||
          key === 'movementUSD'
        ) {
          const normalizedCellValue = parseFloat(cellValue)
            .toFixed(2)
            .toString(); // Normalize raw value
          const formattedCellValue = formatNumber(cellValue).toLowerCase(); // Formatted value for display
          return (
            formattedCellValue.includes(filterValue) ||
            normalizedCellValue.includes(filterValue)
          );
        }

        const stringValue = cellValue.toString().toLowerCase();
        return stringValue.includes(filterValue);
      });
    });

    setSortedDataSource(filtered);
  };

  const handleClearFilters = () => {
    setFilters({});
    setSortedDataSource(dataSource);
  };

  const renderAction = (dataIndex) => {
    if (dataIndex === 'actions') {
      return (
        // <button style={{ opacity: 0, cursor: 'default' }}>Clear Filters</button>
        <button onClick={actionOnClick} className={styles.actionButton}>
          Clear Filters
        </button>
      );
    }

    return (
      <>
        <button onClick={handleClearFilters} className={styles.actionButton}>
          Clear Filters
        </button>
      </>
    );
  };

  return (
    <div
      className={styles.tableContainer}
      style={{ height: sticky && '45rem' }}
    >
      <table className={styles.table}>
        <thead
          className={styles.tableHeader}
          style={{ position: sticky && 'sticky', top: sticky && 0 }}
        >
          <tr>
            {dataConfig.map((config) => (
              <th key={config.dataIndex}>
                <div style={{ display: 'flex' }}>
                  {config.label}
                  {config.dataIndex !== 'action' &&
                    config.dataIndex !== 'actions' && (
                      <div style={{ marginLeft: 3 }}>
                        <KeyboardDoubleArrowUpIcon
                          onClick={() =>
                            onClickHeaderSortingAsc(config.dataIndex)
                          }
                          sx={{ fontSize: 20 }}
                        />
                        <KeyboardDoubleArrowDownIcon
                          onClick={() =>
                            onClickHeaderSortingDesc(config.dataIndex)
                          }
                          sx={{ fontSize: 20 }}
                        />
                      </div>
                    )}
                </div>

                {config.dataIndex !== 'action' &&
                config.dataIndex !== 'actions' &&
                searchColumn ? (
                  <>
                    <input
                      type="text"
                      value={filters[config.dataIndex] || ''}
                      placeholder={`${config.label}`}
                      onChange={(e) =>
                        handleFilterChange(config.dataIndex, e.target.value)
                      }
                      className={styles.tableInput}
                    />
                  </>
                ) : (
                  renderAction(config.dataIndex)
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {sortedDataSource?.map((record, rowIndex) => (
            <tr key={rowIndex}>
              {dataConfig.map((config) => {
                if (config.render) {
                  return (
                    <td key={config.dataIndex}>
                      {config.render(record, rowIndex)}
                    </td>
                  );
                }
                return (
                  <td key={config.dataIndex}>
                    {renderCell(sortedDataSource[rowIndex], config.dataIndex)}
                  </td>
                );
              })}
            </tr>
          ))}
          {grandTotal && (
            <tr>
              {dataConfig.map((_, index) => (
                <>
                  <td style={{ fontWeight: 'bold' }} key={`empty-${index}`}>
                    {showGrandTotal(index)}
                  </td>
                </>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PivotTableV2;
