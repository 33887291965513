import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Dropdown, Modal, Button } from 'react-bootstrap';
import CreatePtModal from './CreatePortfolioModal';
import Spinner from '../common/spinner';
import CommonTable from '../common/CommonTable/CommonTable';
import axios from 'axios';
import { AddCircleOutlineOutlined, EditOutlined, DeleteOutlineOutlined, HighlightOff, CleaningServices } from '@mui/icons-material';
import AccountantModal from './AccountantModal';
import { postAsyncV2, deleteAsync } from '../common/HttpClient';
import { set } from 'date-fns';

export default () => {
    // Variables
    const base_url = process.env.REACT_APP_BASE_URL;
    const getId = localStorage.getItem('sub_Id');
    const roleId = localStorage.getItem('role')?.split(',') ?? [];
    const jwt = localStorage.getItem('access_token');
    const accountantRef = useRef();
    const portfolioRef = useRef();

    // States
    const [loading, setLoading] = useState(true);
    const [portfolios, setPortfolios] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [portId, setPortId] = useState('');
    const [show1, setShow1] = useState(false);
    const [showPurgeConfirmationDlg, setShowPurgeConfirmationDlg] = useState(false);
    const [showPurgeSuccessModal, setShowPurgeSuccessModal] = useState(false);

    // Events
    const handleDropDown = () => {
        if (dropdown === true) {
            setDropdown(false)
        } else {
            setDropdown(true)
        }
    };

    const handleClose = () => {
        setShow1(false);
    };

    const loadData = async () => {
        setLoading(true);
        const config = {
            method: 'post',
            url: `${base_url}/portfolio`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                userId: getId,
                type: 'TREASURY'
            }
        }

        await axios(config)
            .then(function (response) {
                setLoading(false);
                setPortfolios(response.data.results.portfolios);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    };

    // Methods
    const handleAccountant = async (row) => {
        await accountantRef.current.showModal(row.portfolio_id, row.portfolio_name);
    };

    const handleDeleteAccountant = async (accountant_id, portfolio_id) => {
        await accountantRef.current.deleteAccountantAsync(accountant_id, portfolio_id);
    };

    const handleShow1 = (row, rowIndex) => {
        setPortId(row.portfolio_id)
        setShow1(true)
    };

    const handlePurgeData = async () => {
        setLoading(true);
            setShowPurgeConfirmationDlg(false);

        await postAsyncV2('treasure/purge', { portfolioId: portId }, { 'Authorization': `Bearer ${jwt}` });
        
        setLoading(false);
        if(loading === false){
            setShowPurgeSuccessModal(true);
        }
    };

    const handleShowEp = (row) => {
        portfolioRef.current.editPortfolio(row.portfolio_id, row.portfolio_name);
    }

    // delete portfolio name
    const handleDeleteUpdate = async () => {
        var resp = await deleteAsync(`deletePortfolioData`, { type: 'TREASURY', portfolio_id: portId, userId: getId });
        await loadData();
    };

    const columns = [
        {
            dataField: 'portfolio_name',
            text: 'Name',
            sort: true,
            key: 'portfolio_id',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>{row.portfolio_name}</>
                )
            }
        }
    ];

    const admin_columns = [{
        dataField: 'portfolio_id',
        text: 'Accountant',
        sort: true,
        key: 'portfolio_id',
        formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
                <ul style={{ marginLeft: '-3em' }}>
                    {row.accountants.map((accountant) => (
                        <li
                            key={row.portfolioId + accountant.accountant_id}
                            style={{ color: '#FFC107', whiteSpace: 'break-spaces' }}
                        >
                            <span className="namePortData">
                                {' '}
                                {accountant.username.charAt(0).toUpperCase() +
                                    accountant.username.slice(1)}
                            </span>{' '}
                            <span
                                style={{ color: '#b30000', cursor: 'pointer' }}
                                onClick={() => { handleDeleteAccountant(accountant.accountant_id, row.portfolio_id) }}
                            >
                                <HighlightOff style={{ height: '16px' }} />
                            </span>
                        </li>
                    ))}
                </ul>
            )
        }
    },
    {
        dataField: '',
        text: 'Action',
        sort: false,
        key: 'portfolio_id',
        formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
                roleId.includes('superadmin') || roleId.includes('admin')
                    ? (<div
                        style={{
                            cursor: 'pointer',
                            lineHeight: 'normal'
                        }}
                        onClick={handleDropDown}
                    >
                        <Dropdown>
                            <Dropdown.Toggle
                                id={'options-button'}
                                variant="borderless-dark"
                                bsPrefix="no-chevron"
                                size="sm"
                                style={{
                                    color: '#FFC107',
                                    fontSize: '25px',
                                    fontWeight: '700'
                                }}
                            >
                                ...
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                style={{
                                    willChange: 'transform',
                                    fontSize: '14px',
                                    background: '#222429',
                                    border: '0.2em solid white'
                                }}
                            >

                                <Dropdown.Item
                                    href="#"
                                    onClick={async () => await handleAccountant(row)}
                                >
                                    <AddCircleOutlineOutlined
                                        style={{
                                            fontSize: '14px',
                                            color: '#FFC107',
                                            marginRight: '4px',
                                            marginLeft: '-15px',
                                            marginTop: '-3px'
                                        }}
                                    />
                                    <span>Add Accountant</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() => handleShowEp(row)}
                                >
                                    <EditOutlined
                                        style={{
                                            fontSize: '14px',
                                            color: '#FFC107',
                                            marginRight: '4px',
                                            marginLeft: '-15px',
                                            marginTop: '-3px'
                                        }}
                                    />
                                    <span>Edit Portfolio Name</span>
                                </Dropdown.Item>
                                {
                                    roleId.includes('superadmin') || roleId.includes('admin') ?
                                        <>
                                            <Dropdown.Item
                                                href="#"
                                                onClick={() => handleShow1(row, rowIndex)}
                                            >
                                                <DeleteOutlineOutlined
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#f7595c',
                                                        marginRight: '4px',
                                                        marginLeft: '-15px',
                                                        marginTop: '-3px'
                                                    }}
                                                />
                                                <span style={{ color: '#f7595c' }}>Delete</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href="#"
                                                onClick={() => {
                                                    setPortId(row.portfolio_id);
                                                    setShowPurgeConfirmationDlg(true);
                                                }}
                                            >
                                                <CleaningServices
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#f7595c',
                                                        marginRight: '4px',
                                                        marginLeft: '-15px',
                                                        marginTop: '-3px'
                                                    }}
                                                />
                                                <span style={{ color: '#f7595c' }}>Purge Data</span>
                                            </Dropdown.Item></> : <></>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>)
                    : (<></>)
            )
        }
    }
    ];

    if (roleId.includes('superadmin') || roleId.includes('admin')) { Array.prototype.push.apply(columns, admin_columns) }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={12} md={12}>
                        <Row className="d-flex p-2 pageheader">
                            <Col lg={3}>
                                <Row>
                                    <Col lg={10}>
                                        <h3 className="pagetitle" style={{ marginLeft: '0px' }}>Treasury Portfolios</h3>
                                    </Col>
                                    <Col lg={2}>
                                        <CreatePtModal
                                            portfolioType='TREASURY'
                                            callbackEvnt={() => loadData()}
                                            ref={portfolioRef}
                                        ></CreatePtModal>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={9}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={12}>
                        {loading
                            ? (
                                <Spinner
                                    style={{
                                        position: 'fixed',
                                        top: '20em',
                                        left: '59%',
                                        height: '70px',
                                        width: '70px'
                                    }}
                                    animation="border"
                                    variant="primary"
                                />
                            )
                            : null}
                        <CommonTable
                            loading={loading}
                            data={portfolios}
                            columns={columns}
                            className="portfolio"
                        />
                    </Col>
                </Row>
            </Container>
            <AccountantModal
                callbackEvnt={() => loadData()}
                ref={accountantRef}
            />

            <Modal
                show={show1}
                onHide={handleClose}
                style={{
                    width: '30rem',
                    marginTop: '17rem',
                    overflow: 'hidden',
                    marginLeft: '35%',
                    backgroundColor: '#222429',
                    height: '8rem',
                    border: '1px solid white',
                    borderRadius: '15px'
                }}
            >
                <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                    <Modal.Title
                        style={{
                            color: 'white',
                            fontSize: '16px',
                            marginTop: '-5%',
                            marginLeft: '11%'
                        }}
                    >
                        Are you sure you want to Delete this portfolio ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer
                    style={{
                        backgroundColor: '#222429',
                        border: 'none',
                        paddingRight: '34%',
                        marginTop: '-3%'
                    }}
                >
                    <Button
                        variant="success"
                        style={{
                            width: '25%',
                            marginBottom: '2%',
                            backgroundColor: '#006400'
                        }}
                        onClick={() => {
                            handleDeleteUpdate()
                            handleClose()
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleClose}
                        style={{ width: '25%', backgroundColor: '#b30000' }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showPurgeConfirmationDlg}
                onHide={() => setShowPurgeConfirmationDlg(false)}
                style={{
                    width: '30rem',
                    marginTop: '17rem',
                    overflow: 'hidden',
                    marginLeft: '35%',
                    backgroundColor: '#222429',
                    height: '10rem',
                    border: '1px solid white',
                    borderRadius: '15px'
                }}
            >
                <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                    <Modal.Title
                        style={{
                            color: 'white',
                            fontSize: '16px',
                            marginTop: '-5%',
                            marginLeft: '11%'
                        }}
                    >
                        Are you sure you want to <b>PURGE</b> all the data for this portfolio? It will not reversible.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer
                    style={{
                        backgroundColor: '#222429',
                        border: 'none',
                        paddingRight: '34%',
                        marginTop: '-3%'
                    }}
                >
                    <Button
                        variant="success"
                        style={{
                            width: '25%',
                            marginBottom: '2%',
                            backgroundColor: '#006400'
                        }}
                        onClick={() => {
                            handlePurgeData();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {setShowPurgeConfirmationDlg(false)}}
                        style={{ width: '25%', backgroundColor: '#b30000' }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showPurgeSuccessModal}
                onHide={() => setShowPurgeSuccessModal(false)}
                style={{
                    width: '30rem',
                    marginTop: '17rem',
                    overflow: 'hidden',
                    marginLeft: '35%',
                    backgroundColor: '#222429',
                    height: '8rem',
                    border: '1px solid white',
                    borderRadius: '15px'
                }}
            >
                <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                    <Modal.Title
                        style={{
                            color: 'white',
                            fontSize: '16px',
                            marginTop: '-5%',
                            marginLeft: '15%',
                            textAlign: 'center'
                        }}
                    >
                        Data Has Been Purge Successfully
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer
                    style={{
                        backgroundColor: '#222429',
                        border: 'none',
                        marginTop: '-3%',
                        paddingRight:'auto',
                        paddingLeft:'auto'
                    }}
                >
                    <Button
                        variant="success"
                        style={{
                            width: '25%',
                            marginBottom: '2%',
                            backgroundColor: '#006400'
                        }}
                        onClick={() => {
                            setShowPurgeSuccessModal(false);
                        }}
                    >
                        Close
                    </Button>
                  
                </Modal.Footer>
            </Modal>
        </>
    );
};