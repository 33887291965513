import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import PivotTableV2 from './components/PivotTableV2.jsx';
import Pagination from './components/Pagination.jsx';
import { formatDate } from '../helpers/common';
import { sanitiseParams } from '../helpers/modifyJson.js';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './components/Table.module.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: '#212529',
    color: '#ffffff',
  },
});

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const TRANSACTION_LABELS = [
  {
    value: 'byClient',
    label: 'Client',
  },
  { value: 'byCountry', label: 'Country' },
  { value: 'byCountryBank', label: 'Bank' },
  { value: 'byCurrency', label: 'Currency' },
  { value: 'byName', label: 'Name' },
  { value: 'byType', label: 'Account Type' },
];

const renameKey = (obj, oldKey, newKey) => {
  if (obj.hasOwnProperty(oldKey)) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  return obj;
};

const LedgerTransactionRecords = () => {
  const [searchParams] = useSearchParams();

  const paramsObject = Object.fromEntries(searchParams.entries());

  const [responseData, setResponseData] = useState();
  const [tableDataConfig, setTableDataConfig] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchFilters, setSearchFilters] = useState({
    ...paramsObject,
    date: new Date(paramsObject.date).toISOString(),
    pageSize: 100,
    pageIndex: pageIndex,
    orderBy: 'date',
    sorting: 'desc',
  });
  const [clearSearchFilters, setClearSearchFilters] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const debounceTimeout = useRef(null);

  const hasParams = Array.from(searchParams.keys()).length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageIndex]);

  useEffect(() => {
    const init = async () => {
      const response = await postAsync('treasure/excel-record', {
        ...paramsObject,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
      });

      setSearchFilters({
        ...paramsObject,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
      });
      setResponseData(response?.result);
    };

    init();
    responseDataConfig();
  }, [hasParams, clearSearchFilters]);

  // useEffect(() => {
  //   const init = async () => {
  //     const response = await postAsync('treasure/excel-record', {
  //       ...paramsObject,
  //       ...searchFilters,
  //       pageIndex: pageIndex,
  //     });
  //     setResponseData(response?.result);
  //   };

  //   init();
  //   responseDataConfig();
  // }, [pageIndex]);

  const responseDataConfig = () => {
    if (paramsObject.category === 'byClient') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byCountry') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }

    if (paramsObject.category === 'byCountryBank') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byCurrency') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byName') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byType') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
        },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'bankRegion', label: 'Country' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'clientCode', label: 'Client' },
        { dataIndex: 'boCode', label: 'Name' },
        { dataIndex: 'pvNo', label: 'PV No' },
        { dataIndex: 'chequeNo', label: 'Cheque No' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'credit', label: 'Credit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
  };

  const handlePageChange = async (newPage) => {
    setPageIndex(newPage);
    const response = await postAsync('treasure/excel-record', {
      ...paramsObject,
      ...searchFilters,
      pageIndex: newPage,
    });
    setResponseData(response?.result);
  };

  const getLabelData = () => {
    if (paramsObject.category === 'byClient') {
      return paramsObject.ClientCode;
    }
    if (paramsObject.category === 'byCountry') {
      return paramsObject.Region;
    }
    if (paramsObject.category === 'byCountryBank') {
      return paramsObject.BankCode + ' ' + paramsObject.Region;
      // return paramsObject.BankCode;
    }
    if (paramsObject.category === 'byCurrency') {
      return paramsObject.Currency;
    }
    if (paramsObject.category === 'byName') {
      return paramsObject.NameCode;
    }
    if (paramsObject.category === 'byType') {
      return paramsObject.Type;
    }

    return paramsObject.ClientCode;
  };

  const handleSearchColumn = (searchColumns) => {
    // Clear the previous timeout if the function is called again before the timeout completes
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after a delay
    debounceTimeout.current = setTimeout(async () => {
      // shallow copy of the searchColumns object to prevent input reset
      let currentSearchFilters = { ...searchColumns };
      const renamedDate = renameKey(currentSearchFilters, 'recordDate', 'date');

      const body = {
        ...paramsObject,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
        searchFilter: sanitiseParams(renamedDate),
      };

      setSearchFilters(body);
      setPageIndex(1);
      const response = await postAsync('treasure/excel-record', body);
      setResponseData(response?.result);

      // Clear the reference after the timeout executes
      debounceTimeout.current = null;
    }, 1500); // Delay of 1.5 seconds
  };

  const handleOnAscendingSorting = async (dataIndex) => {
    let newPageIndex = searchFilters.pageIndex;
    if (searchFilters.orderBy !== dataIndex) {
      newPageIndex = 1;
      setPageIndex(1);
    }
    const response = await postAsync('treasure/excel-record', {
      ...searchFilters,
      pageIndex: newPageIndex,
      orderBy: dataIndex === 'recordDate' ? 'date' : dataIndex,
      sorting: 'asc',
    });
    setSearchFilters({
      ...searchFilters,
      orderBy: dataIndex,
      pageIndex: newPageIndex,
      sorting: 'asc',
    });
    setResponseData(response?.result);
  };

  const handleOnDescendingSorting = async (dataIndex) => {
    let newPageIndex = searchFilters.pageIndex;
    if (searchFilters.orderBy !== dataIndex) {
      newPageIndex = 1;
      setPageIndex(1);
    }
    const response = await postAsync('treasure/excel-record', {
      ...searchFilters,
      pageIndex: newPageIndex,
      orderBy: dataIndex === 'recordDate' ? 'date' : dataIndex,
      sorting: 'desc',
    });
    setSearchFilters({
      ...searchFilters,
      orderBy: dataIndex,
      pageIndex: newPageIndex,
      sorting: 'desc',
    });

    setResponseData(response?.result);
  };

  const handleClearFilters = () => {
    setClearSearchFilters((prev) => !prev);
  };

  const onEditRecord = (record) => {
    setFormData(record);
    setOpenEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postAsync('treasure/update-excel-record', {
      recordId: formData.recordId,
      payeeOrRemitter:
        formData.payeeOrRemitter === ''
          ? 'No Record'
          : formData.payeeOrRemitter,
      description:
        formData.description === '' ? 'No Record' : formData.description,
      pvNo: formData.pvNo === '' ? 'No Record' : formData.pvNo,
      chequeNo: formData.chequeNo === '' ? 'No Record' : formData.chequeNo,
    });

    if (!response?.success) {
      return;
    }

    setOpenEditModal(false);
    setShowSuccess(true);
    const refetchData = await postAsync('treasure/excel-record', {
      ...paramsObject,
      date: new Date(paramsObject.date).toISOString(),
      pageSize: 100,
      pageIndex: 1,
      orderBy: 'date',
      sorting: 'desc',
    });
    setResponseData(refetchData?.result);
  };

  const actionColumn = {
    dataIndex: 'actions',
    label: 'Action',
    render: (record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            // selfAlign: 'center',
          }}
        >
          <button onClick={() => onEditRecord(record)}>Edit</button>
        </div>
      );
    },
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Row>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Ledger Transaction Records</h3>
            </span>
          </Row>
          <Row
            style={{
              marginBottom: '8px',
              justifyContent: 'space-between',
            }}
          >
            <span className="p-2 pageheader">
              <h3 className="pagetitle">
                {
                  TRANSACTION_LABELS.find(
                    (item) => item.value === paramsObject.category
                  )?.label
                }
                : {getLabelData()}
              </h3>
            </span>
            {/* <span onClick={handleClearFilters} className={styles.actionButton3}>
              Clear Filters
            </span> */}
          </Row>
        </Col>
      </Row>
      <PivotTableV2
        dataSource={responseData?.items}
        dataConfig={[...tableDataConfig, actionColumn]}
        onSearch={handleSearchColumn}
        onSortAsc={handleOnAscendingSorting}
        onSortDesc={handleOnDescendingSorting}
        onClearFilter={clearSearchFilters}
        actionOnClick={handleClearFilters}
        sticky
      />
      <Pagination
        totalItems={responseData?.totalItem}
        currentPage={pageIndex}
        onPageChange={handlePageChange}
      />
      <StyledDialog
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        // maxWidth={'xl'}
        fullWidth
      >
        <DialogTitle>Edit </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Payee/Remitter</span>
              <input
                name="payeeOrRemitter"
                value={
                  formData.payeeOrRemitter === 'No Record'
                    ? ''
                    : formData.payeeOrRemitter
                }
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Description</span>
              <input
                name="description"
                value={
                  formData.description === 'No Record'
                    ? ''
                    : formData.description
                }
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>PV No.</span>
              <input
                name="pvNo"
                value={formData.pvNo === 'No Record' ? '' : formData.pvNo}
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Cheque No.</span>
              <input
                name="chequeNo"
                value={
                  formData.chequeNo === 'No Record' ? '' : formData.chequeNo
                }
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <span
              onClick={() => setOpenEditModal(false)}
              className={styles.actionButton3}
              style={{ cursor: 'pointer' }}
            >
              Cancel
            </span>
            <button className={styles.actionButton3} type="submit">
              Submit
            </button>
          </DialogActions>
        </form>
      </StyledDialog>
      <StyledDialog open={showSuccess} onClose={() => setShowSuccess(false)}>
        <DialogTitle>Edit Success</DialogTitle>
        <DoneOutlineIcon sx={{ marginX: 'auto', fontSize: 40 }} />
        <Button
          onClick={() => setShowSuccess(false)}
          variant="outlined"
          sx={{
            my: 3,
            mx: 2,
            color: '#ffc107',
            ':hover': { bgcolor: 'none', borderColor: '#ffc107' },
            borderColor: '#ffc107',
          }}
        >
          Close
        </Button>
      </StyledDialog>
    </Container>
  );
};

export default LedgerTransactionRecords;
