import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useActionState } from '../hooks/useActionState';

const OPTIONS = [
  'byClient',
  'byCountry',
  'byCountryBank',
  'byCurrency',
  'byName',
  'byType',
];

const OPTIONS_MAP = [
  { value: 'byClient', label: 'Client' },
  { value: 'byCountry', label: 'Country' },
  { value: 'byCountryBank', label: 'Bank' },
  { value: 'byCurrency', label: 'Currency' },
  { value: 'byName', label: 'Name' },
  { value: 'byType', label: 'Account' },
];

const OptionsMenu = ({
  rowId,
  rowData,
  usedOptions,
  onOptionClick,
  currentCategory,
  anchorEl,
  open,
  onClose,
  setSelectedItemMenu,
}) => {
  const [currentAction, setCurrentAction] = useState([]);

  const availableOptions = OPTIONS_MAP.filter(
    (option) => !usedOptions.some((item) => item.option === option.value)
  );

  const handleOptionClick = (option) => {
    setCurrentAction(option.value);
    onOptionClick({ rowId: rowId, option: option.value });
  };

  return (
    // <div
    //   className="options-menu"
    //   style={{
    //     display: 'flex',
    //     marginTop: '10px',
    //     // position: 'relative',
    //     backgroundColor: 'red',
    //   }}
    // >
    // <div
    //   style={{
    //     backgroundColor: 'white',
    //     flexDirection: 'column',
    //     display: 'flex',
    //     padding: '10px',
    //     // position: 'absolute',
    //     // right: 0,
    //     // left: 0,
    //     width: '70%',
    //     borderRadius: '5px',
    //     // maxHeight: '8rem',
    //     overflowY: 'auto',
    //   }}
    // >
    //   {availableOptions.map((option) => (
    //     <button
    //       key={option}
    //       onClick={() => handleOptionClick(option)}
    //       className="option-button"
    //       style={{
    //         marginTop: '10px',
    //         color: 'black',
    //         backgroundColor: '#ffc107',
    //       }}
    //     >
    //       {option.label}
    //     </button>
    //   ))}
    // </div>
    <Menu
      anchorEl={anchorEl}
      open={open}
      // onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {availableOptions.map((option) => (
        <MenuItem
          key={option.value}
          onClick={() => handleOptionClick(option)}
          // sx={{ backgroundColor: '#ffc107', marginBottom: '5px' }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
    // </div>
  );
};

export default OptionsMenu;
