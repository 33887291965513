import { sanitiseParams } from '../helpers/modifyJson';
const axios = require('axios');
const base_url = process.env.REACT_APP_BASE_URL;


export const getAsync = async(url, header = null) => {
    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header)
            headers = {...headers, ...header };

        var resp = await axios({
            method: 'GET',
            url: `${base_url}/${url}`
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const postAsync = async(url, payload, header = null) => {
    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header)
            headers = Object.assign(headers, header);
        const token = localStorage.getItem('newApiToken')

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        var resp = await axios({
            headers: headers,
            method: 'POST',
            url: `${base_url}/${url}`,
            data: payload
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const deleteAsync = async(url, payload, header = null) => {
    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header)
            headers = {...headers, ...header };

        var resp = await axios({
            method: 'DELETE',
            url: `${base_url}/${url}`,
            data: payload
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const postAsyncV2 = async(url, payload, header = null) => {
    const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header) headers = Object.assign(headers, header);
        const token = localStorage.getItem('newApiToken');

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        var resp = await axios({
            headers: headers,
            method: 'POST',
            url: `${base_url}/${url}`,
            data: sanitiseParams(payload),
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};